import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../../components/hero/hero-chevron";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import SEO from "../../../components/seo/seo";

const LeadershipBoard = () => {
  const title = "Meet Our Banking Executives";
  const description =
    "Meet the WaFd Bank leadership team - Our executives are dedicated to developing the right financial tools to improve your financial fitness.";

  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/about-us/leadership-board/hero-leadership-090823-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/about-us/leadership-board/hero-leadership-090823-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/about-us/leadership-board/hero-leadership-090823-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/about-us/leadership-board/hero-leadership-090823-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/about-us/leadership-board/hero-leadership-090823-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/about-us/leadership-board/hero-leadership-090823-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/about-us/leadership-board/hero-leadership-090823-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      brentBeardall: file(relativePath: { eq: "employees/headshot-brent-beardall-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      kelliHolz: file(relativePath: { eq: "employees/headshot-kelli-holtz-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      cathyCooper: file(relativePath: { eq: "employees/headshot-cathy-cooper-083023.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      ryanMauer: file(relativePath: { eq: "employees/headshot-ryan-mauer-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      kimRobinson: file(relativePath: { eq: "employees/headshot-kim-robinson-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      jamesEndrizzi: file(relativePath: { eq: "employees/headshot-james-endrizzi-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      stephenGraham: file(relativePath: { eq: "employees/headshot-stephen-graham-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      davidGrant: file(relativePath: { eq: "employees/headshot-david-grant-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      randyTalbot: file(relativePath: { eq: "employees/headshot-randy-talbot-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      steveSingh: file(relativePath: { eq: "employees/headshot-steve-singh-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      lindaBrower: file(relativePath: { eq: "employees/headshot-linda-bower-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      sylviaHampel: file(relativePath: { eq: "employees/headshot-sylvia-hampel-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      seanSingleton: file(relativePath: { eq: "employees/headshot-sean-singleton-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      shawnBice: file(relativePath: { eq: "employees/headshot-shawn-bice-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      maxYzaguirre: file(relativePath: { eq: "employees/headshot-m-max-yzaguirre-020524.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      bradleyShuster: file(relativePath: { eq: "employees/headshot-bradley-m-shuster-020524.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      TomVanHemelryck: file(relativePath: { eq: "employees/headshot-tom-van-hemelryck-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      MarliseFisher: file(relativePath: { eq: "employees/headshot-marlise-fisher-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      GaryHaines: file(relativePath: { eq: "employees/headshot-gary-haines-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      DanLaCoste: file(relativePath: { eq: "employees/rp-Dan-LaCoste.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      ToddGerber: file(relativePath: { eq: "employees/headshot-todd-gerber-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      MichelleCoons: file(relativePath: { eq: "employees/headshot-michelle-coons-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      TonyBarnard: file(relativePath: { eq: "employees/headshot-tony-barnard-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      TomPozarycki: file(relativePath: { eq: "employees/headshot-tom-pozarycki-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      DennisZender: file(relativePath: { eq: "employees/headshot-dennis-zender-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      KenMcLain: file(relativePath: { eq: "employees/headshot-ken-mclain-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
      DoronJoseph: file(relativePath: { eq: "employees/headshot-doron-joseph-021523.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
  `);

  const heroChevronData = {
    id: "about-us-leadership-board-hero",
    ...getHeroImgVariables(imgData),
    altText: "WaFd Bank Executive Management Committee",
    heroBody: {}
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/investor-relations/leadership-board"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-leadership-090823.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/investor-relations",
      title: "Investor Relations"
    },
    {
      id: 3,
      active: true,
      title: "Leadership Team"
    }
  ];

  const executiveManagementData = [
    {
      name: "Brent Beardall",
      role: "President, Chief Executive Officer, and Vice Chairman",
      imageUrl: imgData.brentBeardall.childImageSharp.gatsbyImageData
    },
    {
      name: "Kelli Holz",
      role: "Executive Vice President and Chief Financial Officer",
      imageUrl: imgData.kelliHolz.childImageSharp.gatsbyImageData
    },
    {
      name: "Cathy Cooper",
      role: "Executive Vice President and Chief Consumer Banker",
      imageUrl: imgData.cathyCooper.childImageSharp.gatsbyImageData
    },
    {
      name: "Ryan Mauer",
      role: "Executive Vice President and Chief Credit Officer",
      imageUrl: imgData.ryanMauer.childImageSharp.gatsbyImageData
    },
    {
      name: "Kim Robison",
      role: "Executive Vice President and Chief Operating Officer",
      imageUrl: imgData.kimRobinson.childImageSharp.gatsbyImageData
    },
    {
      name: "James Endrizzi",
      role: "Executive Vice President and Chief Commercial Banker",
      imageUrl: imgData.jamesEndrizzi.childImageSharp.gatsbyImageData
    }
  ];

  const boardOfDirectorsData = [
    {
      name: "Stephen M. Graham",
      role: "Board of Director, Chairman of the Board",
      description:
        "Stephen M. Graham joined the WaFd Bank Board of Directors in April 2019 and was appointed Chairman of the Board in January 2022. As Managing Partner of Fenwick & West LLP in Seattle, former Partner at Orrick and Perkins Coie, and a graduate of Yale Law School, Mr. Graham brings over forty years of experience representing public and private corporations in mergers, acquisitions, and strategic planning. Mr. Graham served for six years as a co-chair of the SEC Advisory Committee on Small and Emerging Companies and is currently serving on the Board of Directors at Fred Hutchinson Cancer Research Center.",
      imageUrl: imgData.stephenGraham.childImageSharp.gatsbyImageData
    },
    {
      name: "Brent Beardall",
      role: "President, Chief Executive Officer, Vice Chairman",
      description:
        "Brent Beardall is the President & Chief Executive Officer of WaFd Bank. Mr. Beardall joined WaFd Bank in 2001 as Vice President & Controller. In 2003, he was promoted to Chief Financial Officer, a position he served in for eleven years prior to being assigned to his current role with responsibility for all client-facing activities of the bank. He was awarded the title of President in 2016. On April 1, 2017, he became only the sixth CEO in the bank's 100 year history. In January 2022, Mr. Beardall was appointed Vice Chairman of the Board.",
      imageUrl: imgData.brentBeardall.childImageSharp.gatsbyImageData
    },
    {
      name: "David Grant",
      description:
        "David Grant joined the WaFd Bank board of directors in September of 2012. Grant is the Managing Partner of Catalyst Storage Partners, a privately-held international firm specializing in self-storage development, investment and consulting. Prior to forming Catalyst, Grant spent 20 years with Shurgard Storage Centers, an NYSE-traded Real Estate Investment Trust that owned and operated a portfolio of over 600 properties. Grant began his career as a Certified Public Accountant in 1975 with Touche Ross & Co, where he worked until joining Shurgard in 1985.",
      imageUrl: imgData.davidGrant.childImageSharp.gatsbyImageData
    },
    {
      name: "Randy H. Talbot",
      description:
        "Randall H. Talbot, a WaFd Bank Director since July, 2012, has been the Managing Director of Talbot Financial LLC, an investment advisory firm, since 2010. Mr. Talbot served as Director, CEO and President of Symetra Financial Corporation from 2004 to June, 2010, and as Director of Concur Technologies, Inc. from March 2008 to November 2014. Mr. Talbot joined the former parent of Symetra Financial Corporation, Safeco Corporation, in 1998 and from 1998 to 2004 served as President of Safeco Life Insurance Company.",
      imageUrl: imgData.randyTalbot.childImageSharp.gatsbyImageData
    },
    {
      name: "Steve Singh",
      description:
        "Steve Singh, who joined the WaFd Bank board of directors in August, 2018, is the Chief Executive Officer and Chairman of the Board at Docker, an innovative cloud technology company based in San Francisco. Mr. Singh is best known as the CEO and Co-Founder of Concur Technologies, a Bellevue, Washington based travel and expense management company. He built Concur into an enterprise success over the course of 20 years before selling to SAP in 2014. Singh is also Executive Chairman of Center, a financial software company. Singh holds various leadership roles in other technology companies and community organizations.",
      imageUrl: imgData.steveSingh.childImageSharp.gatsbyImageData
    },
    {
      name: "Linda Brower",
      description:
        "Linda Brower is the former Executive Vice President and Chief Administration Officer at WaFd Bank from 2003 until her retirement in 2016. Her extensive banking career spans four decades including past leadership roles at Bank of America and US Bank.",
      imageUrl: imgData.lindaBrower.childImageSharp.gatsbyImageData
    },
    {
      name: "Sylvia Hampel",
      description:
        "Sylvia Hampel is a business owner and consultant who opened her own cleaning service in 1995 and grew it into one of the largest women-owned businesses in Idaho. Her company, Clearview Cleaning, expanded to over 450 custodial teams operating throughout Idaho and three other western states before selling the company to facility services giant KBS. Sylvia is a licensed real estate broker and owns her own real estate development company.",
      imageUrl: imgData.sylviaHampel.childImageSharp.gatsbyImageData
    },
    {
      name: "Sean Singleton",
      description:
        "Managing Principal of Oglethorpe Capital LLC. Through Oglethorpe, Sean works to develop the next phase of disruptive technologies in Aerospace & Defense (A&D), Technology, Media & Telecom (TMT) and Next Generation Energy (NGE). He is an advisor to cutting edge firms, backed by some of the world's most respected venture firms. Prior to founding Oglethorpe, Sean gained his corporate finance and capital markets experience at J.P. Morgan Chase. He is also a former Air Force Officer and DoD innovation executive.",
      imageUrl: imgData.seanSingleton.childImageSharp.gatsbyImageData
    },
    {
      name: "Shawn Bice",
      description:
        "Shawn Bice, a WaFd Bank Director since January 2021, is currently Corporate Vice President, Cloud Security, Microsoft. Shawn has been a technology executive for more than two decades. Most recently, as President of products and technology at Splunk, a cloud security and analytics company. Bice is also a former Amazon Web Services Vice President of Cloud Data Services (2016-2021). He started his career at Microsoft as an executive leading SQL Server and Azure Data, amongst other enterprise services (1997-2016). His two decades of experience in technology leadership roles will support the Board's efforts to adopt the latest technologies and secure the bank's digital transformation to best serve its clients.",
      imageUrl: imgData.shawnBice.childImageSharp.gatsbyImageData
    },
    {
      name: "M. Max Yzaguirre",
      description:
        "Max Yzaguirre has served on the Boards of Directors of publicly-listed and privately-held companies, as well as non-profit organizations, with experience serving as Chairman of Audit & Compliance, Risk and Compensation Committees. Mr. Yzaguirre has over 35 years of leadership experience in domestic and international business, government and law, and expertise in a wide variety of industries and sectors, including electricity, oil and gas, banking, real estate, telecommunications and private equity investing. Mr. Yzaguirre has specific expertise initiating businesses in evolving market conditions.",
      imageUrl: imgData.maxYzaguirre.childImageSharp.gatsbyImageData
    },
    {
      name: "Bradley M. Shuster",
      description:
        "Mr. Shuster has served as Executive Chairman and Chairman of the Board of NMI Holdings, Inc. (NASDAQ: NMIH) since January 2019. Mr. Shuster founded National MI and served as Chairman and Chief Executive Officer of the company from 2012 to 2018. He also serves as Chairman of the Board of McGrath RentCorp (NASDAQ: MGRC), and was previously an independent director of Luther Burbank Corporation (NASDAQ: LBC).  Prior to founding National MI, Mr. Shuster was a senior executive of The PMI Group, Inc. (NYSE: PMI), where he served as Chief Executive Officer of PMI Capital Corporation. Before joining PMI in 1995, Mr. Shuster was a partner at Deloitte LLP, where he served as partner-in-charge of Deloitte's Northern California Insurance and Mortgage Banking practices. Mr. Shuster holds a B.S. from the University of California, Berkeley and an M.B.A. from the University of California, Los Angeles. In addition, he has received both CPA and CFA certifications.",
      imageUrl: imgData.bradleyShuster.childImageSharp.gatsbyImageData
    }
  ];

  const regionalPresidentsData = [
    {
      name: "Tom Van Hemelryck",
      role: "Idaho Regional President",
      imageUrl: imgData.TomVanHemelryck.childImageSharp.gatsbyImageData
    },
    {
      name: "Marlise Fisher",
      role: "Utah Regional President",
      imageUrl: imgData.MarliseFisher.childImageSharp.gatsbyImageData
    },
    {
      name: "Gary Haines",
      role: "Northern Oregon Regional President",
      imageUrl: imgData.GaryHaines.childImageSharp.gatsbyImageData
    },
    {
      name: "Dan LaCoste",
      role: "Southern Oregon Regional President",
      imageUrl: imgData.DanLaCoste.childImageSharp.gatsbyImageData
    },
    {
      name: "Todd Gerber",
      role: "Arizona Regional President",
      imageUrl: imgData.ToddGerber.childImageSharp.gatsbyImageData
    },
    {
      name: "Michelle Coons",
      role: "New Mexico Regional President",
      imageUrl: imgData.MichelleCoons.childImageSharp.gatsbyImageData
    },
    {
      name: "Tony Barnard",
      role: "Texas Regional President",
      imageUrl: imgData.TonyBarnard.childImageSharp.gatsbyImageData
    },
    {
      name: "Tom Pozarycki",
      role: "Northern Washington Regional President",
      imageUrl: imgData.TomPozarycki.childImageSharp.gatsbyImageData
    },
    {
      name: "Dennis Zender",
      role: "Southern Washington Regional President",
      imageUrl: imgData.DennisZender.childImageSharp.gatsbyImageData
    },
    {
      name: "Ken McLain",
      role: "Inland Northwest Regional President",
      imageUrl: imgData.KenMcLain.childImageSharp.gatsbyImageData
    },
    {
      name: "Doron Joseph",
      role: "Nevada Regional President",
      imageUrl: imgData.DoronJoseph.childImageSharp.gatsbyImageData
    }
  ];

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>WaFd Bank Leadership Team</h1>
        {/* Executive Management Committee */}
        <h2 className="text-success">Executive Management Committee</h2>
        <div className="row py-4 mb-5 border-bottom-3 border-success">
          {executiveManagementData.map((data, index) => (
            <div
              key={index}
              id={`emc-${data.name.toLocaleLowerCase().replace(/\s+/g, "-")}-info`}
              className="col-sm-4 col-md-3 col-lg mb-4 text-center"
            >
              <GatsbyImage image={data.imageUrl} alt={`${data.name}, WaFd Bank ${data.role}`} />
              <div className="font-weight-semi-bold text-gray-80 mt-3">{data.name}</div>
              <div>{data.role}</div>
            </div>
          ))}
        </div>
        {/* Board of Directors */}
        <h2 className="text-success">Board of Directors</h2>
        <div className="row py-4">
          {boardOfDirectorsData.map((data, index) => (
            <div key={index} id={`bod-${data.name.toLocaleLowerCase().replace(/\s+/g, "-")}-info`} className="col-md-6">
              <div className="row justify-content-center">
                <div className="col-md-8 col-lg-5 text-center mb-4">
                  <GatsbyImage image={data.imageUrl} alt={`${data.name}, WaFd Bank Board of Directors`} />
                  <div className="font-weight-semi-bold text-gray-80 mt-3">{data.name}</div>
                  <div>{data.role}</div>
                </div>
              </div>
              <p>{data.description}</p>
            </div>
          ))}
        </div>
        {/* Diversity Table */}
        <div className="table-responsive pb-4 border-bottom-3 border-success">
          <table id="diversity-table" className="table table-bordered">
            <thead className="bg-info text-white">
              <tr>
                <th colSpan="4" id="bdm-title">
                  Board Diversity Matrix (As of March 1, 2024)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ width: "35%" }}>&nbsp;</td>
                <td className="font-weight-bold" style={{ width: "15%" }} id="bdm-gender-female">
                  Female
                </td>
                <td className="font-weight-bold" style={{ width: "15%" }} id="bdm-gender-male">
                  Male
                </td>
              </tr>
              <tr>
                <td id="bdm-total-directors">Total Number of Directors</td>
                <td
                  colSpan="2"
                  className="d-none d-sm-table-cell text-center align-middle"
                  id="bdm-total-directors-females"
                >
                  11
                </td>
                <td
                  colSpan="3"
                  className="d-table-cell d-sm-none text-center align-middle"
                  id="bdm-total-directors-males"
                >
                  11
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="font-weight-bold" id="bdm-gender-identity">
                  Part I: Gender Identity
                </td>
              </tr>
              <tr>
                <td id="board-diversity-gender-identity-totals">Directors</td>
                <td className="align-middle text-center text-sm-left" id="bdm-gender-identity-female">
                  2
                </td>
                <td className="align-middle text-center text-sm-left" id="bdm-gender-identity-male">
                  9
                </td>
              </tr>
              <tr>
                <td colSpan="4" className="font-weight-bold" id="bdm-demographic-background">
                  Part II: Demographic Background
                </td>
              </tr>
              <tr>
                <td id="bdm-demgraphic-background-aa-black">African American or Black</td>
                <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-aa-black-females">
                  0
                </td>
                <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-aa-black-males">
                  2
                </td>
              </tr>
              <tr>
                <td id="bdm-demgraphic-background-hispanic-latinx">Hispanic or Latinx</td>
                <td
                  className="align-middle text-center text-sm-left"
                  id="bdm-demgraphic-background-hispanic-latinx-females"
                >
                  1
                </td>
                <td
                  className="align-middle text-center text-sm-left"
                  id="bdm-demgraphic-background-hispanic-latinx-males"
                >
                  1
                </td>
              </tr>
              <tr>
                <td id="bdm-demgraphic-background-asian">Asian</td>
                <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-asian-females">
                  0
                </td>
                <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-asian-males">
                  1
                </td>
              </tr>
              <tr>
                <td id="bdm-demgraphic-background-white">White</td>
                <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-white-females">
                  1
                </td>
                <td className="align-middle text-center text-sm-left" id="bdm-demgraphic-background-white-males">
                  5
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Regional Presidents */}
        <div className="pt-4">
          <h2 className="text-success">Regional Presidents</h2>
          <div className="row">
            {regionalPresidentsData.map((data, index) => (
              <div
                key={index}
                id={`rp-${data.name.toLocaleLowerCase().replace(/\s+/g, "-")}-info`}
                className="col-sm-4 col-md-3 col-lg-2 mb-4 text-center"
              >
                <GatsbyImage image={data.imageUrl} alt={`${data.name}, WaFd Bank ${data.role}`} />
                <div className="font-weight-semi-bold text-gray-80 mt-3">{data.name}</div>
                <div>{data.role}</div>
                <p>{data.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};
export default LeadershipBoard;
